<template lang="pug">
Layout
  Hello
</template>
<script>

export default {
  components: {
    Hello: () => import('./Hello.vue'),
    Layout: () => import('./Layout.vue'),
  },
}
</script>
<style lang="stylus">

</style>
